import React, { useEffect, useState } from 'react';
import HeaderBar from '../../../components/header-bar';
import InputTextField from '../../../components/input-text';
import AddressSelector from '../../../components/address-selector';
import BottomSheet from '../../../components/bottom-sheet';
import Button from '../../../components/button';
import { useInput, useSelectableList } from 'rooks';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import { callAppFunc } from '../../../utils/jsbridge/index.js';

// mode
// add:  新增
// edit: 編輯
const ReceiverManage = ({ mode, location }) => {
  const api = useAPI();

  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [address, setAddress] = useState('');
  const [update, setUpdate] = useState(false);
  const [addressTag, setAddressTag] = useState('');
  const [note, setNote] = useState('');
  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [receiveTypeValue, setReceiveTypeValue] = useState('');
  const [elevatorValue, setElevatorValue] = useState('');
  // const [phoneFormatValid, setPhoneFormatValid] = useState(true)

  const validData = (param) => {
    let isValid = true;
    if (param.fullName === '') {
      alert('請輸入收件人姓名');
      isValid = false;
      return isValid;
    }

    if (param.phone === '') {
      alert('請輸入手機電話');
      isValid = false;
      return isValid;
    }

    if (param.phone.length !== 10) {
      alert('手機格式輸入錯誤');
      isValid = false;
      return isValid;
    } //TODO:02FIX

    if (!phoneFormatValid(param.phone)) {
      alert('手機格式輸入錯誤');
      isValid = false;
      return isValid;
    }

    if (param.address === '') {
      alert('請輸入收件人地址');
      isValid = false;
      return isValid;
    }

    if (param.deliveryPointValue === '') {
      alert('請選擇配送方式');
      isValid = false;
      return isValid;
    }

    if (elevatorValue === '') {
      alert('請選擇有無電梯');
      isValid = false;
      return isValid;
    }

    return isValid;
  };

  const submit = (event) => {
    event.preventDefault();

    const fullName = event.target[0].value;
    const lastName = '';
    const phone = event.target[1].value;
    const address = event.target[2].value;
    const tag = event.target[3].value;
    const deliveryNote = event.target[10].value;
    //const isDefault = event.target[4].checked;
    const isDefault = true;
    // const deliveryIsElevator = selectionElevator[1] === '1' ? true : false;
    const deliveryPointValue = receiveTypeValue;

    const hasElevator = elevatorValue === '1' ? true : false;
    console.log('event', event);

    if (
      !validData({
        fullName,
        phone,
        address,
        isDefault,
        deliveryIsElevator: hasElevator,
        deliveryPointValue,
        lastName,
      })
    ) {
      return false;
    }
    showLoading();
    if (mode === 'add') {
      api
        .newAddress({
          fullName,
          phone,
          address,
          isDefault,
          deliveryIsElevator: hasElevator,
          deliveryPointValue,
          lastName,
          tag,
          deliveryNote,
        })
        .then((res) => {
          dismissLoading();
          alert('新增成功');
          callAppFunc('backHome', {});
        })
        .catch((error) => {
          dismissLoading();
          console.log(error);
        });
    } else {
      const addressId = location.state.data.data.addressId;

      api
        .updateAddress({
          fullName,
          phone,
          address,
          isDefault,
          deliveryIsElevator: hasElevator,
          deliveryPointValue,
          addressId,
          lastName,
          tag,
          deliveryNote,
        })
        .then((res) => {
          if (!res) {
            return;
          }
          dismissLoading();
          //alert('修改成功');
          alert('修正完成! 本次設定將在下次重新選擇配送地址時生效。');
          callAppFunc('backHome', {});
        })
        .catch((error) => {
          dismissLoading();
          console.log(error);
        });
    }
  };

  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        setReceiveTypeOptions(res);
        console.log('getReceiveType', res);
        // if (mode !== 'add') {
        //   setReceiveTypeValue(location.state.data.data.deliveryPointValue);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOptions();
    if (mode !== 'add') {
      console.log(location.state.data);
      setName(location.state.data.data.name);
      setMobile(location.state.data.data.phone);
      setAddress(location.state.data.data.address);
      //setDefaultCheck(location.state.data.data.isDefault);
      setNote(location.state.data.data.deliveryNote);
      setAddressTag(location.state.data.data.tag);
      const elevator = location.state.data.data.deliveryIsElevator ? '1' : '0';
      setElevatorValue(elevator);

      // console.log(
      //   'location.state.data.data.deliveryPointValue',
      //   location.state.data
      // );
      setReceiveTypeValue(location.state.data.data.deliveryPointValue);
      setUpdate(true);
    } else {
      setUpdate(true);
    }
  }, []);

  const phoneFormatValid = (phone, isEdit = true) => {
    if ((!isEdit && phone === undefined) || phone === '') {
      return true;
    }

    var mobileRegex = /^09\d{8}$/;
    var result = mobileRegex.test(phone);

    return result;
  };

  return (
    <>
      <HeaderBar
        title={`收件人 ${mode === 'add' ? '新增' : '資料編輯'}`}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />
      {update ? (
        <form onSubmit={submit}>
          <div className="receiver-manage-page">
            <InputTextField
              label="收件人"
              placeholder="姓名"
              marginBottom={12}
              text={name}
            />
            <InputTextField
              label="手機號碼"
              placeholder="手機"
              type="phone"
              mLength="10"
              marginBottom={12}
              text={mobile}
              onChangeValue={(v) => {
                setMobile(v);
              }}
              message="手機號碼格式錯誤"
              showMessage={!phoneFormatValid(mobile, true)}
            />
            <InputTextField
              label="收件地址"
              placeholder="地址"
              text={address}
            />
            <InputTextField label="標籤" placeholder="標籤" text={addressTag} />
            {/* <label className="checkbox set-default">
              <input
                type="checkbox"
                name="1"
                value="1"
                checked={defaultCheck}
                onChange={() => {
                  if (defaultCheck) {
                    setDefaultCheck(false);
                  } else {
                    setDefaultCheck(true);
                  }
                }}
              />
              <span>設爲預設</span>
            </label> */}

            <div className="radios">
              <span className="radio-name">電梯</span>
              <input
                type="radio"
                id="y"
                name="elevator"
                value="1"
                checked={'1' === elevatorValue}
                onChange={(e) => {
                  setElevatorValue(e.target.value);
                }}
              />
              <label htmlFor="y">有</label>
              <input
                type="radio"
                id="n"
                name="elevator"
                value="0"
                checked={'0' === elevatorValue}
                onChange={(e) => {
                  setElevatorValue(e.target.value);
                }}
              />
              <label htmlFor="n">無</label>
            </div>
            <div className="radios">
              <span className="radio-name">簽收</span>
              {receiveTypeOptions &&
                receiveTypeOptions.map((item, index) => (
                  <div key={`div-receive-${index}`}>
                    <input
                      type="radio"
                      id={`receive-${index}`}
                      name="sign"
                      value={item.value}
                      checked={item.value === receiveTypeValue}
                      onChange={(e) => {
                        setReceiveTypeValue(e.target.value);
                      }}
                    />
                    <label htmlFor={`receive-${index}`}>{item.name}</label>
                  </div>
                  // <div key={`div-receive-${index}`}>
                  //   <input
                  //     type="radio"
                  //     id={`receive-${index}`}
                  //     name="sign"
                  //     value={item.value}
                  //     checked={item.value === receiveTypeValue}
                  //     onChange={(e) => {
                  //       setReceiveTypeValue(e.target.value);
                  //     }}
                  //   />
                  //   <label htmlFor={`receive-${index}`}>{item.name}</label>
                  // </div>
                ))}

              {/* <input
                type="radio"
                id="sign-y"
                name="sign"
                checked={defaultSign({ index: 0 })}
                onChange={() => toggleSign({ index: 0 })()}
              />
              <label htmlFor="sign-y">放在門口</label>
              <input
                type="radio"
                id="sign-n"
                name="sign"
                checked={defaultSign({ index: 1 })}
                onChange={() => toggleSign({ index: 1 })()}
              />
              <label htmlFor="sign-n">在門口收件</label>
              <input
                type="radio"
                id="sign-a"
                name="sign"
                checked={defaultSign({ index: 2 })}
                onChange={() => toggleSign({ index: 2 })()}
              />
              <label htmlFor="sign-a">在1樓門口收件</label> */}
              <p className="notice">
                如果您的樓層有安全管制，請通知管理室協助簽收。
              </p>
              <h2 className="memo label">地址備註</h2>
              <textarea
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                placeholder="樓層/房號、公司大樓名稱、其他地址備註"
              ></textarea>
            </div>
          </div>

          <BottomSheet persistant noTitle noRounded>
            <Button
              text={`${mode === 'add' ? '新增' : '修改'}`}
              type="submit"
            />
          </BottomSheet>
        </form>
      ) : undefined}
      <style jsx>
        {`
          textarea {
            box-sizing: border-box;
            width: 100%;
            min-height: 70px;
            resize: none;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: 20px;
            background: #ffffff;
            border: 1px solid #00000040;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 14px 12px 13px 12px;

            color: rgb(51, 51, 51);
            &::placeholder {
              color: rgba(51, 51, 51, 0.5);
            }
          }
          .label {
            font-family: Noto Sans TC;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: #333333;
            display: block;
            margin-bottom: 8px;
          }
          .memo {
            padding-top: 11px;
          }
          .receiver-manage-page {
            padding: 26px 20px 126px 20px;

            .set-default {
              margin-bottom: 22px;
            }

            .radios {
              padding-top: 12px;
              .notice {
                padding-top: 9px;
                font-size: 14px;
                line-height: 20px;

                color: #000000;
              }
            }
            input[type='radio'] {
              display: none;
            }
            .radio-name {
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              padding-right: 10px;
            }
            input[type='radio'] + label {
              position: relative;
              font-size: 14px;
              line-height: 20px;
              padding-left: 26px;
              color: #000000;
              & ~ label {
                margin-left: 14px;
              }
            }
            input[type='radio'] + label::before {
              position: absolute;
              left: 0;
              top: 0;
              content: '';
              display: inline-block;
              width: 16px;
              height: 16px;
              background-color: #ffffff;
              border: solid 1px #d2d2d2;
              border-radius: 50%;
              box-sizing: border-box;
            }
            input[type='radio']:checked + label::before {
              border: solid 6px #5fd2da;
            }
          }
        `}
      </style>
    </>
  );
};

export default ReceiverManage;
